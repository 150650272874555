<template>
    <div id="contribute" class="container page">
        <h2 id="contact-us"><mark>Contribute to Chachi</mark></h2>

        <h3>Chachi is a shared resource that continues to grow with help from the community.</h3>

        <p>Would you like to contribute to Chachi's activity box? You could be a teacher, a parent or just someone interested in education. Fill the form below to send us your entry –</p>

        <h5 style="text-align:center"><a href="https://forms.gle/xTqqPBKRDSZwugGY7" title="Contribute to Chachi – Google Form">Contribute to Chachi - Google Form</a></h5>

        <p>If we add your contribution to Chachi, we will definitely contact you and acknowledge your contribution on the site.</p>

        <p>If you prefer, you can also send us an email at <code>contact [at] chachi (dot) app</code>.</p>

        <p>Have other ideas for making Chachi a better resource? Perhaps you have suggestions for improving some of the existing activities on Chachi? We would love to hear from you. Send us an email!</p>
    </div>
</template>

<script>
export default {
	name: 'Contribute',
}
</script>

<style scoped>
</style>
